#header{
    padding-top: 1vh;
  }
  .cartitem {
    flex-direction: row;
    h1{
      font-size: 10.5vh;
    }
    img{
      width: 13vh;
      margin: 0 0.7vw;
      padding: 0 6%;
    }
  }
  #shopLogo, #currency {
    font-size: 10.5vh;
  }


  #checkout {
    cursor: pointer;
  }