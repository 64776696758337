.navarea {
    overflow: hidden;  
    
    .capsule {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
  }
  .cartitem {
      h1{
        font-size: 4.9rem;
        margin: 0;
        text-transform: uppercase;
      }
      img{
        padding: 0 5%;
      }
  }
  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  nav {
    width: 100vw;
    background: white;
  }
  
  ul {
    padding-left: 0;
    display: flex;
    list-style: none outside none;
    justify-content: center;
    align-items: center;
  }
  
  li {
    padding: 0 50px;
  }
  
  a,
  a:visited,
  a:active {
    text-decoration: none;
    color: black;
  }
  
  .cartitem {
    position: relative;
    display: flex;
    float: right;
    h1{
      font-weight: 100;
      line-height: initial;
    }
  }
  
  #bag{
    h1{
      font-weight: 100;
      line-height: initial;
    }
  } 
  
  #shopLogo{
    font-size: 4.9rem;
    color: #000000;
    letter-spacing: 3px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    font-weight: 100;
    line-height: initial;
    text-decoration: none;
  }
  
  #currency {
    font-family: "Lucida Grande";
    font-size: 4.9rem;
    line-height: 0;    
    font-weight: 800;
  }
  
  #bag {
      display: flex;
      align-items: baseline ;
  }
