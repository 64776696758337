/**
* PARTIALS 
**/
@import '_partials/reset.scss';

/**
* MOBILE 
**/
@import 'mobile/layout.scss';
@import 'mobile/header.scss';
@import 'mobile/product.scss';
@import 'mobile/footer.scss';

/**
* DESKTOP 
**/

@media screen and (min-width: 850px) {
    @import 'desktop/header.scss';
}
