footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
}

#ironemes {
  white-space: nowrap;
  box-sizing: border-box;
  animation: marquee1 70s linear infinite;


  span {
    text-transform: uppercase;
    font-size: 10.5vh;
    padding-right: 7vw;
  }
}

.marquee__inner1 {
	width: fit-content;
	display: flex;
	position: relative;
	--offset1: 1rem;
  --move-initial1: calc(-0% + var(--offset1));
	--move-final1: calc(-87% + var(--offset1));
	transform: translate3d(var(--move-initial1), 0, 0);
	}

@keyframes marquee1 {
	0% {
		transform: translate3d(var(--move-initial1), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final1), 0, 0);
	}
}

