.item {
    padding-left: 20px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
}

#slideshow {
    // display: flex;

    animation: marquee 120s linear infinite alternate;
    img {
      padding-right: 0.5%;
      max-height: 65vh;
    }
}

.description {
  margin: 3vh 0;
  padding-left: 42vw;
  padding-right: 1vw;
  font-size: 1.8vw;
  a {
    color: #000;
    text-decoration: underline;
  }
}

.marquee__inner {
	width: fit-content;
	display: flex;
	position: relative;
	--offset: 1rem;
  --move-initial: calc(-1% + var(--offset));
  --move-final: calc(-250% + var(--offset));
	transform: translate3d(var(--move-initial), 0, 0);
	}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}